import { fetchCampaignList } from '../../../services/campaign';
import { createCampaign } from '../../../services/campaignv1';
import ChannelBalance from '@/components/channelBalance/channelBalance';
import CampaignMixin from '@/mixins/campaignMixin';

const moment = require('moment');
const _ = require('lodash');

export default {
  name: 'campaignList',
  props: {
    channelType: String,
    channel: String
  },
  mixins: [CampaignMixin],
  components: {
    ChannelBalance
  },
  data() {
    return {
      creatingCampaign: false,
      fetchingData: false,
      campaignList: [],
      sortingInfo: [],

      searchFilter: '',
      statusFilter: [],
      campaignType: ['oneTime'],
      filterOptions: {
        status: [
          { label: 'Draft', value: 'draft' },
          { label: 'Scheduled', value: 'scheduled' },
          { label: 'Running', value: 'running' },
          { label: 'Ended', value: 'ended' }
        ],
        campaignType: [
          { label: 'One Time', value: 'oneTime' },
          { label: 'Journey', value: 'journey' }
        ]
      },

      pageOptions: {
        pageSize: 20,
        pageNumber: 1,
        total: null
      }
    };
  },

  methods: {
    onSearch: _.debounce(function () {
      this.resetPaginationAndSearch();
    }, 700),

    resetPaginationAndSearch() {
      this.pageOptions.pageNumber = 1;
      this.fetchCampaignList();
    },

    async onCreateCampaignClick() {
      // Show campaign name dialog
      let result = null;
      try {
        result = await this.$prompt('Campaign Name', 'Create Campaign', {
          confirmButtonText: 'Create Campaign',
          cancelButtonText: 'Cancel',
          inputPattern: /.{1,100}/,
          inputErrorMessage: 'Campaign name must be of 1-100 characters.'
        });
      } catch (err) {
        // Ignore cancel button click;
        return;
      }

      let loading = this.$loading({
        lock: true,
        text: 'Creating Campaign',
        spinner: 'el-icon-loading',
        background: '#f5f7facc'
      });

      // Create Campaign
      try {
        // Create Campaign.
        let params = {
          name: result.value,
          channel: this.channelType
        };
        result = await createCampaign(params);

        // Redirect to general info update page
        let campaignId = result.data;
        this.$router.push({ name: 'campaignGeneralInfoUpdate', params: { id: campaignId } });
        loading.close();
      } catch (err) {
        loading.close();
        this.errorToast('Failed to create campaign. Please contact support.');
        this.reportError(err);
      }
    },

    getCampaignDetailsPageLink(campaign) {
      if (campaign.status == 'draft') {
        return '/campaign/update/general/' + campaign.id;
      } else {
        return '/campaign/report/' + campaign.id;
      }
    },

    async fetchCampaignList(isPageLoad) {
      try {
        this.fetchingData = true;

        let params = {
          search: this.searchFilter,
          channelType: this.channelType,
          statusFilter: this.statusFilter.length > 0 ? this.statusFilter : undefined,
          campaignTypeFilter: this.campaignType.length > 0 ? this.campaignType : undefined,
          pageOptions: this.pageOptions
        };

        // Modify Query-String
        if (!isPageLoad) {
          const query = Object.assign({}, this.$route.query);
          query.search = params.search;
          query.campaignType = JSON.stringify(params.campaignTypeFilter);
          query.statusFilter = JSON.stringify(params.statusFilter);
          query.pageOptions = JSON.stringify(params.pageOptions);
          this.$router.push({ query });
        }

        // Fetch List
        let response = await fetchCampaignList(params);

        // Format List
        this.campaignList = response.data.list;
        for (var i = 0; i < this.campaignList.length; i++) {
          this.campaignList[i].start_time = this.campaignList[i].start_time && this.campaignList[i].start_time != 'N/A' ? this.getDisplayTime(this.campaignList[i].start_time) : 'N/A';
          this.campaignList[i].end_time = this.campaignList[i].end_time && this.campaignList[i].end_time != 'N/A' ? this.getDisplayTime(this.campaignList[i].end_time) : 'N/A';
          this.campaignList[i].created_at = this.getDisplayTime(this.campaignList[i].created_at);
          // this.campaignList[i].updator_name = this.campaignList[i].updator_name;
        }
        this.pageOptions.total = response.data.total;

        this.fetchingData = false;
      } catch (err) {
        this.reportError(err);
        this.errorToast('Failed to read campaigns. Please contact support.');
        this.fetchingData = false;
      }
    },

    getDisplayTime(date) {
      if (!date) {
        return `N/A`;
      }
      var dt = moment(date, 'x');
      return dt.format('DD-MMM-YYYY <br/> hh:mm A');
    },

    isBalanceButtonVisible() {
      if (this.channelType == 'sms') return true;
      if (this.channelType == 'email') {
        let companyList = {
          '24t4w21536krkjsrso': 'countrybean',
          '1xh60ck6yhl60p8x5e': 'sleepyowl',
          '5ab58tmule583u4y': 'zymrat',
          '1xh60ckiupldztucjs': 'salty',
          '1xh60ck8kmlfm3moq3': 'gush beauty',
          '5ab5823yklbxq4hyy': 'nourish with sim',
          '1xh60ck9kylemgh3e8': 'hoppup',
          '1xh60ckkwolf2byha3': 'sundayskin',
          '5ab58vlilgesoev0': 'wildermart'
        };
        if (companyList[this.$store.state.token.companyId]) {
          return true;
        }
      }
      return false;
    }
  },

  mounted() {
    this.start_time = moment().inspect();

    // Modify Query-String
    const query = this.$route.query;
    this.searchFilter = query.search ? query.search : this.searchFilter;
    this.statusFilter = query.statusFilter ? JSON.parse(query.statusFilter) : this.statusFilter;
    this.campaignType = query.campaignType ? JSON.parse(query.campaignType) : this.campaignType;
    this.pageOptions = query.pageOptions ? JSON.parse(query.pageOptions) : this.pageOptions;

    console.log('query is', query, this.searchFilter, this.statusFilter, this.campaignType, this.pageOptions);
    this.fetchCampaignList(true);
  }
};
